
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-6">
        <h4>Filters:</h4>
      </div>
      <div class="col-md-6">

      </div>
    </div>

    <div class="row" style="margin-bottom: 20px;">
      <div class="col-md-6">
        <div>
          By date:
        </div>

        <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
        <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
        <button type="button" class="btn btn-danger" @click="getTransactionsData">Filter</button>

      </div>
      <div class="col-md-2">
        <div>
          By Outlet:
        </div>
        <select class="form-control" v-model="outlet" @change="getTransactionsData">
            <option  value="" > All </option>
            <option :value="shop.id" v-for="shop in outlets"> {{ shop.name }}</option>
        </select>
      </div>
      <div class="col-md-2" >
        <div>
          By Partner:
        </div>
        <select class="form-control" v-model="partner" @change="getTransactionsData">
          <option value="0" > All </option>
          <option :value="partner.id" v-for="partner in partners"> {{ partner.name }}</option>
        </select>
      </div>
    </div>

    <br/>

    <div style="overflow-x: scroll;">
      <b-table striped hover :items="transactions" :fields="columns">

        <!--<template #cell(no)="data">
          {{ data.index + 1 }}
        </template>-->

        <template v-slot:cell(customer)="data">
          <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/customer/'+data.item.customer.id" v-if="data.item.customer">
            {{ data.value.name }}
          </a>
        </template>
        <template v-slot:cell(outlet)="data">
          <span v-if="data.value.name">{{ data.value.name }}</span>
        </template>
        <template v-slot:cell(partner)="data">
          <span v-if="data.value.name">{{ data.value.name }}</span>
        </template>
        <template v-slot:cell(amount)="data">
          <span>KES. {{ data.value | number('0,0') }}</span>
        </template>
        <template v-slot:cell(loyaltyPoints)="data">
          <span v-if="data.item.transactionApprovalStatus == 'REVERSED' "> -{{ data.value | number('0,0') }}</span>
          <span>{{ data.value | number('0,0') }}</span>
        </template>
        <template v-slot:cell(transactedBy)="data">
          <span v-if="data.item.transactedBy">{{ data.item.transactedBy.name }}</span>
        </template>

      </b-table>
    </div>

    <div class="row">
      <div class="col-md-12">
        <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
          <ul class="pagination justify-content-center">
            <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
            <li v-for="page in pagesNumber"
                v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
            <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect'
  import Button from "../../components/button/Button";
  import { required,requiredIf} from 'vuelidate/lib/validators'
  import CodeInput from "vue-verification-code-input"

export default {
  data() {
    return {
      disabled:false,
      isLoading: false,
      fullPage: true,
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      offset: 4,
      columns:["created","amount","customer","outlet","partner","phoneNumber","transactedBy",
        "reference","thirdPartyPosting"],
     transactions:[],
      start_date:"all",
      end_date:"all",
      outlet:0,
      partner:0,
      outlets:[],
      partners:[]
    };
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
    permissions(){
      return localStorage.getItem("permissions");
    },
    role(){
      return localStorage.getItem("role");
    }
  },
  components: {
    Button,
    Loading,
    DatePicker,
    Multiselect,
  },
  mounted (){
    this.getOutletData();
    this.getPartnersData();
    this.getTransactionsData();
  },
  methods: {
    ...mapActions(["getPartners","getNonAccrualTransactions","getOutlets"]),

    changePage: function (page) {
      this.pagination.current_page = page;
      this.getTransactionsData();
    },
    getPartnersData(){
      let self = this;
      this.getPartners()
          .then(function (partners) {
            self.partners = partners;

          })
          .catch(function (error) {

          })
    },
    getTransactionsData(){
      let self = this;

      this.isLoading = true;

      this.getNonAccrualTransactions({page: this.pagination.current_page, from_date:this.start_date,
        to_date:this.end_date,outlet_id:this.outlet, partner_id:this.partner})
        .then(function (transactions) {

          self.isLoading = false;

          self.transactions = transactions.content;
          self.pagination.current_page = transactions.number;
          self.pagination.total = transactions.totalElements;
          self.pagination.per_page = transactions.numberOfElements;
          self.pagination.from = transactions.pageable.offset + 1 ;
          self.pagination.to = transactions.pageable.pageSize;
          self.pagination.last_page = transactions.totalPages;
        })
        .catch(function (error) {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getOutletData(){
      let self = this;

      this.isLoading = true

      this.getOutlets()
        .then(function (outlets) {


          self.outlets = outlets;

          self.getTransactionsData();


        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    }
  }
};
</script>

<style >
  table tr th{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  table td{
    padding: 10px;
  }
</style>
